<script setup>

import { ref } from "vue"
import { useAuthStore } from "@/store/auth"
import { useAxiStore } from "@/store/axi"

const authStore = useAuthStore()
const axiStore = useAxiStore()

const axis = ref([])
const loading = ref(true)
const filter = ref(null)
const totalRecords = ref(0)

const clearFilter = () => {
  filter.value = null
  search()
}

const search = (e = {}) => {
  loading.value = true

  const page = (e.page || 0) + 1
  axiStore.index({ filter: filter.value, page }).then((res) => {
    axis.value = res.data.axis.data
    totalRecords.value = res.data.axis.total

    loading.value = false
  })
}

search()

</script>

<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <h5>Axi</h5>
        <DataTable
          class="p-datatable-gridlines"
          dataKey="id"
          filterDisplay="row"
          :lazy="true"
          :loading="loading"
          :paginator="true"
          responsiveLayout="scroll"
          :rowHover="true"
          :rows="10"
          :totalRecords="totalRecords"
          :value="axis"
          @page="search($event)"
        >

          <template #header>
            <div class="flex justify-content-between flex-column sm:flex-row">
              <Button type="button" icon="pi pi-filter-slash" label="Clear" class="p-button-outlined mb-2" @click="clearFilter" />
              <div class="col-12 md:col-4">
                <div class="p-inputgroup">
                  <InputText v-model="filter" placeholder="Login" />
                  <Button icon="pi pi-search" class="p-button-warning" @click="search" />
                </div>
              </div>
            </div>
          </template>
          <template #empty>
						No records.
          </template>
          <template #loading>
						Loading. Please wait.
          </template>

          <Column field="user_group" header="User Group" />

          <Column field="login" header="Login">
            <template #body="{data}">
              <Chip :label="String(data.login)" class="custom-chip-primary px-3" />
            </template>
          </Column>

          <Column field="country" header="Country" />

          <Column header="Options">
            <template #body="{data}">
              <router-link :to="`/axis/${data.id}`">
                <Button icon="pi pi-eye" class="p-button-rounded p-button-primary mr-2 mb-2" />
              </router-link>

              <template v-if="authStore.hasPermission('members') && data.account">
                <router-link :to="`/members/${data.account.member_id}`">
                  <Button icon="pi pi-users" class="mr-2 mb-2" />
                </router-link>
              </template>
            </template>
          </Column>

        </DataTable>
      </div>
    </div>
  </div>
</template>
